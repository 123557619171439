.note-list .item{
    font-family: 'Geist Regular';
    font-size: 13px;
    line-height: 1rem;
    /* height: 30px; */
    padding: 0px 5px;
    padding-left: 0px;
    border-bottom: 1px solid #e4e4e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.note-list .item:hover{
    background-color: #f8fafc;
}

.note-list .item .folder-menutooltip{
    display: flex;
    align-items: center;
    color: #919eab;
}

.note-list .item:hover .folder-menutooltip{opacity: 1;}

.note-list .item .folder-menutooltip:hover{color: black;}

.note-list .add-note-btn{
    font-family: "Geist SemiBold";
    font-size: 20px;
    padding: 0px 7px;
    padding-bottom: 2px;
    height: 25px;
    display: flex;
    align-items: center;
    color: black;
    background-color: white;
    margin-right: -7px;
    border-radius: 4px;
}

.note-list .add-note-btn:hover {
    transform: scale(.95);
    background: #EFEFEF;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
}

.select-note-btn{
    /* height: 25px; */
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 0px;
}

.note-list .item:hover .delete-note-btn{
    display: flex;
}

.note-list .note-btn{
    background-color: white;
    border: 1px solid #e4e4e7;
    border-radius: 6px;
    padding: 5px 10px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
}

.note-save-btn{
    font-family: 'Geist Medium';
    font-size: 14px;
}

/* .note-list .item .delete-note-btn{
    display: none;
} */

/* .note-list .delete-note-btn{
    background-color: #f8fafc;
    border-radius: 6px;
    height: 21px;
    padding: 0px 4px;
    display: flex;
    align-items: center;
} */

/* .note-list .delete-note-btn:hover{
    background-color: #EFEFEF;
} */