.links-grid-container {
    padding: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.links-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    margin-top: 20px;
}

.link-item {
    background-color: #f9f9f9;
    padding: 5px 0px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.link-item {
    width: 100%; /* Set a fixed width for each link item */
    display: flex;
    align-items: center;
    text-align: center;

    background-color: white;
    padding: 10px 0px;
    border: 1px solid #e4e4e7;
    border-radius: 4px;
    position: relative; /* For tooltip positioning */
}

.link-text {
    font-family: 'Geist SemiBold';
    font-size: 14px;
    text-align: center;
    justify-content: center;

    flex-grow: 1;
    text-decoration: none;
    color: black;
    overflow: hidden;           /* Hide overflow text */
    white-space: nowrap;        /* Prevent text from wrapping */
    text-overflow: ellipsis;    /* Show ellipsis for overflowed text */
}

.link-text:hover::after {
    content: attr(data-fulltext); /* Tooltip text set to full title */
    position: absolute;
    top: -25px;                  /* Position the tooltip above the link */
    left: 0;
    padding: 4px 8px;
    background-color: #333;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    border-radius: 4px;
    z-index: 1;
    opacity: 0.8;
}


.delete-btn {
    background: none;
    border: none;
    color: black;
    cursor: pointer;

    display: flex;
    align-items: center;
}


.add-link-form {
    display: flex;
    gap: 5px;
    margin-top: 10px;
}

.add-link-form input[type="text"],
.add-link-form input[type="url"] {
    font-family: 'Geist Regular';
    padding: 5px;
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.add-link-form button {
    font-family: 'Geist Medium';
    padding: 10px;
    border: none;
    background-color: hsl(221.2 83.2% 53.3%);
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
}
