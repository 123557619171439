.todolist{
    max-height: 405px;
    overflow-y: scroll;
    width: 100%;
    height:fit-content;
}

.todolist button{
    font-family: "Geist SemiBold";
    font-size: 20px;
    padding: 0px 7px;
    padding-bottom: 2px;
    height: 25px;
    display: flex;
    align-items: center;
    color: black;
    background-color: white;
    margin-right: -7px;
    border-radius: 4px;
}

.todolist button:hover{
    transform: scale(.95);
    background: #EFEFEF;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
}
.todolist button:active{transform: scale(.90)}

.todolist h3{margin: 0px;}

.todolist .item{
    font-family: 'Geist Regular';
    font-size: 14px;
    line-height: 1rem;
    /* height: 28px; */

    padding: 6px 5px 6px 0px;
    border-bottom: 1px solid #e4e4e7;

    display: flex;
    align-items: center;
    column-gap: 10px;
}

.todolist .checkbox.item:hover{
    background-color: #F8FAFC;
}

.todolist input[type="checkbox"] {
    margin: 0px;
    cursor: pointer;
}

input[type="text"] {
    width: 100%;
}

.todolist .input{
    background-color: rgba(255,255,255,0);
}

.todo-line{
    border: 1px solid green;
    width: 100%;
}

.todolist .input{
    border: none;
    outline: none;

    font-family: 'Geist Regular';
    font-size: 14px;
    line-height: 1rem;

    padding: 0px;
    border: 1px solid red;
    width: 100%;
    border: 0px solid white;
}

.todolist input{
    font-family: 'Geist Regular';
    font-size: 14px;
    line-height: 1rem;
    border: 0px solid white;
    padding: 0px;
}

.todolist .inner{
    font-family: 'Geist Regular';
    font-size: 14px;
    line-height: 1rem;

    cursor: text;
    display: flex;
    align-items: center;
}

.todolist .input:focus {
    outline: none;
    box-shadow: none;
  }

  input:focus {
    outline: none;
    box-shadow: none;
  }
  

.timer{
    width: 40px;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: end;
}

.todolist .play-btn, .todolist .pause-btn, .todolist .reset-btn{
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
}

.todolist .timer-btn{
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 4px;

    display: none;
}

.todolist .timer-btn:hover{
    background-color: #EFEFEF;
}


.checkbox .play-btn:hover, .checkbox .pause-btn:hover, .checkbox .reset-btn:hover{
    background-color: #EFEFEF;
    border-radius: 4px;
}

/* .checkbox .play-btn, .checkbox .pause-btn, .checkbox .reset-btn{opacity: 0;} */

.checkbox:hover .play-btn, .checkbox:hover .pause-btn, .checkbox:hover .reset-btn, .checkbox:hover .timer{
    opacity: 1;
}

.timer-actions{
    font-family: 'Geist SemiBold';
    font-size: 12px;
    display: none;
}

.todolist .delete-btn{
    cursor: pointer;
    border-radius: 4px;

    display: flex;
    align-items: center;
    padding: 0px;
    opacity: 0;
    background-color: #F8FAFC;
    color: #919eab;
}

.todolist .delete-btn:hover{
    color: black;
}

.checkbox:hover .delete-btn{
    opacity: 1;
}

.completed {
    text-decoration: line-through;
    opacity: 0.6;
  }