/* src/fonts.css */


@font-face {
    font-family: 'Geist UltraBlack';
    src: url('/public/fonts/geist/statics-woff/Geist-UltraBlack.woff2') format('woff2'),
         url('/public/fonts/geist/statics-woff/Geist-UltraBlack.woff') format('woff'),
         url('/public/fonts/geist/statics-ttf/Geist-UltraBlack.ttf') format('truetype'),
         url('/public/fonts/geist/statics-otf/Geist-UltraBlack.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Geist Black';
    src: url('/public/fonts/geist/statics-woff/Geist-Black.woff2') format('woff2'),
         url('/public/fonts/geist/statics-woff/Geist-Black.woff') format('woff'),
         url('/public/fonts/geist/statics-ttf/Geist-Black.ttf') format('truetype'),
         url('/public/fonts/geist/statics-otf/Geist-Black.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Geist Bold';
    src: url('/public/fonts/geist/statics-woff/Geist-Bold.woff2') format('woff2'),
         url('/public/fonts/geist/statics-woff/Geist-Bold.woff') format('woff'),
         url('/public/fonts/geist/statics-ttf/Geist-Bold.ttf') format('truetype'),
         url('/public/fonts/geist/statics-otf/Geist-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Geist SemiBold';
    src: url('/public/fonts/geist/statics-woff/Geist-SemiBold.woff2') format('woff2'),
         url('/public/fonts/geist/statics-woff/Geist-SemiBold.woff') format('woff'),
         url('/public/fonts/geist/statics-ttf/Geist-SemiBold.ttf') format('truetype'),
         url('/public/fonts/geist/statics-otf/Geist-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Geist Medium';
    src: url('/public/fonts/geist/statics-woff/Geist-Medium.woff2') format('woff2'),
         url('/public/fonts/geist/statics-woff/Geist-Medium.woff') format('woff'),
         url('/public/fonts/geist/statics-ttf/Geist-Medium.ttf') format('truetype'),
         url('/public/fonts/geist/statics-otf/Geist-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Geist Regular';
    src: url('/public/fonts/geist/statics-woff/Geist-Regular.woff2') format('woff2'),
         url('/public/fonts/geist/statics-woff/Geist-Regular.woff') format('woff'),
         url('/public/fonts/geist/statics-ttf/Geist-Regular.ttf') format('truetype'),
         url('/public/fonts/geist/statics-otf/Geist-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Geist Light';
    src: url('/public/fonts/geist/statics-woff/Geist-Light.woff2') format('woff2'),
         url('/public/fonts/geist/statics-woff/Geist-Light.woff') format('woff'),
         url('/public/fonts/geist/statics-ttf/Geist-Light.ttf') format('truetype'),
         url('/public/fonts/geist/statics-otf/Geist-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Geist Thin';
    src: url('/public/fonts/geist/statics-woff/Geist-Thin.woff2') format('woff2'),
         url('/public/fonts/geist/statics-woff/Geist-Thin.woff') format('woff'),
         url('/public/fonts/geist/statics-ttf/Geist-Thin.ttf') format('truetype'),
         url('/public/fonts/geist/statics-otf/Geist-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Geist UltraLight';
    src: url('/public/fonts/geist/statics-woff/Geist-UltraLight.woff2') format('woff2'),
         url('/public/fonts/geist/statics-woff/Geist-UltraLight.woff') format('woff'),
         url('/public/fonts/geist/statics-ttf/Geist-UltraLight.ttf') format('truetype'),
         url('/public/fonts/geist/statics-otf/Geist-UltraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }