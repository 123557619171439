*{
    box-sizing: border-box;
}

body{
    height: 100vh;
    /* background-color: #f4f4f5; */
}

.relative{position: relative;}

/* DISPLAY FLEX */
.d-flex{ display: flex;}
.flex-col{flex-direction: column;}
.justify-center{justify-content: center;}
.justify-between{justify-content: space-between}
.justify-start{justify-content: start;}
.justify-end{justify-content: end;}

.align-center{align-items:center;}
.align-baseline{align-items:baseline;}
.align-start{align-items: start;}
.align-end{align-items: end;}

.flex-wrap{flex-wrap: wrap;}

.row-gap-1rem{row-gap: 1rem;}
.col-gap-1rem{column-gap: 1rem;}

.row-gap-5{row-gap: 5px;}
.row-gap-10{row-gap: 10px;}
.row-gap-15{row-gap: 15px;}
.row-gap-20{row-gap: 20px;}
.row-gap-25{row-gap: 25px;}

.col-gap-5{column-gap: 5px;}
.col-gap-10{column-gap: 10px;}
.col-gap-15{column-gap: 15px;}
.col-gap-20{column-gap: 20px;}
.col-gap-25{column-gap: 25px;}

/* WIDTH */
.w-20{width: 20%;}
.w-25{width: 25%;}
.w-30{width: 30%;}
.w-35{width: 35%;}
.w-40{width: 40%;}
.w-45{width: 45%;}
.w-50{width: 50%;}
.w-55{width: 55%;}
.w-60{width: 60%;}
.w-65{width: 65%;}
.w-70{width: 70%;}
.w-75{width: 75%;}
.w-80{width: 80%;}
.w-85{width: 85%;}
.w-90{width: 90%;}
.w-95{width: 95%;}

.w-100{width: 100%;}
.w-250{width: 250px;}

.max-w-100{max-width:100%}

.max-width-250{max-width: 250px;}
.max-width-300{max-width: 300px;}
.max-width-350{max-width: 350px;}

.h-auto{height: auto}

.h-25{height:25px}

.p-1rem{padding: 1rem;}
.pt-1rem{padding-top: 1rem;}
.pb-1rem{padding-bottom: 1rem;}


.p-5{padding: 5px;}
.p-10{padding: 10px;}
.p-15{padding: 15px;}
.p-20{padding: 20px;}
.pt-15{padding-top: 15px;}
.pt-20{padding-top: 20px;}

.pl-5{padding-left: 5px;}
.pl-10{padding-left: 10px;}
.pl-15{padding-left: 15px;}


.pr-10{padding-right: 10px;}
.pr-15{padding-right: 15px;}
.pr-20{padding-right: 20px;}
.pr-1rem{padding-right:1rem}

.pb-5{padding-bottom: 5px;}

.py-10{padding-top: 10px; padding-bottom: 10px;}
.px-20{padding-right: 20px; padding-left: 20px;}

.mt-5{margin-top: 5px;}
.mt-10{margin-top: 10px;}
.mt-15{margin-top: 15px;}
.mt-20{margin-top: 20px;}

.mt-1rem{margin-top: 1rem;}

.my-10{
    margin-top: 10px;
    margin-bottom: 10px;
}

.my-1rem{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-5{
  margin-left: 5px;
  margin-right: 5px;
}

.mb-1rem{margin-bottom:1rem;}
.mb-5{margin-bottom:5px;}
.mb-10{margin-bottom: 10px;}
.mb-15{margin-bottom:15px;}
.mb-20{margin-bottom:20px;}

.mr-5{margin-right: 5px;}
.mr-10{margin-right: 10px;}
.mr-15{margin-right: 15px;}
.mr-20{margin-right: 20px;}

.ml-5{margin-left: 5px;}
.ml-251{margin-left: 251px}
.ml-225{margin-left: 225px}

.fs-12{font-size:12px}
.d-none{display: none;}

.border-shadow{
    border: 1px solid #e4e4e7;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
    border-radius: calc(4px);
    /* border-radius: calc(.5rem + 4px); */
}

.border{border: 1px solid #e4e4e7;}
.border-top{border-top: 1px solid #e4e4e7;}
.border-b{border-bottom: 1px solid #e4e4e7;}
.border-l{border-left: 1px solid #e4e4e7;}

.shadow{box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);}
.radius{border-radius: 4px;}

.color-black{color:black;}
.color-white{color:white;}
.color-grey{color:grey;}
.bg-color{background-color: #f4f4f5;}
.bg-white{background-color: white;}
.bg-black{background-color: black !important;}
.bg-blue{background: hsl(221.2 83.2% 53.3%)}
.color-blue{color: hsl(221.2 83.2% 53.3%);}

.opacity-0{opacity: 0;}
.opacity-1{opacity: 1;}

.pointer{cursor: pointer;}
.cursor-text{cursor: text;}

.z-1{z-index: 1;}
.z-2{z-index: 2;}
.z-3{z-index: 3;}
.z-4{z-index: 4;}

h3{
    font-family: 'Geist Bold';
    /* font-size: 24px;  */
    font-size: 1.25rem;
    
    letter-spacing: -.025em;
    line-height: 2rem;
    margin-top: 0px;
    margin-bottom: 0px;
}

h4{
    font-size: 1rem;
    margin: 0px;
}

.text-left{text-align: left}
.text-center{text-align: center}
.text-right{text-align: right}

.underline{
  /* text-decoration: underline; */
  border-bottom: 1px solid white;
}

/* .content{min-width: 80%;} */

.card-wall{
    width: 100%;
    /* width: calc(100% - 300px);
    margin-left:300px; */
    padding-top: 0px;
    justify-content: start;
    padding-left: 0px;
}

.card{
    text-align: left;
    background-color: white;

    /* padding:20px; */
    padding: 1.5rem;
    /* padding-top: 15px; */

    border: 1px solid #e4e4e7;
    /* border-radius: calc(6px); */
    border-radius: calc(0.5rem + 4px);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
}

button{
    /* padding: 8px 10px; */
    border: 0px solid #e4e4e7;
    /* border-radius: calc(4px); */
    /* box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1); */
    cursor: pointer;
}




/* SCROLLBAR */
::-webkit-scrollbar {
    width: 6px; /* Set the width of the scrollbar */
    height: 8px;
  }
  
  /* The part of the scrollbar that you drag */
  ::-webkit-scrollbar-thumb {
    background-color: hsl(240 4.8% 95.9%); /* Set the color of the scrollbar thumb */
    border-radius: 15px; /* Optional: make the scrollbar thumb rounded */
  }
  
  /* The track (progress bar) of the scrollbar */
  ::-webkit-scrollbar-track {
    background: transparent; /* Set the color of the track */
  }

  .section-resize{
    position: absolute;
    bottom: 0px;
    right: 50%;
    cursor: ns-resize;

    color: rgba(0, 0, 0, .9);

    display: flex;
    align-items: center;
    background: #e4e4e7;
    border-radius: 2px;
    padding: 3px 0px;

    transform: rotate(90deg);
    z-index: 3;
  }

  .todo-border{
    width: calc(100% + 19px);
    /* margin-left: -1px; */
    position: absolute;
    bottom: 0px;
    border-top: 1px solid #e4e4e7;
    border-right: 1px solid #e4e4e7;
    background: white;
    height: 20px;
    left: -0px;
    z-index: 2;
    background-color: #f9f9f9;
  }

  .first-section{
    width: 58%;
    min-width: calc(100% - 500px)
  }

  .first-container{
    padding-left: 20px;
    width: calc(100% - 20px)
  }

  .drag-handle {
    cursor: grab;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    color: #919eab;
}

.drag-handle:active {
    cursor: grabbing;
}

.sidebar .drag-handle{
  display: inline-block;
  padding-top: 6px;
  padding-left: 0px;
  padding-right: 0px;
}

.page-subtitle{
  white-space: nowrap;
}

.buy-now-hp{
  background: hsl(221.2 83.2% 53.3%);
  font-family: 'Geist Black';
  font-size: 40px;
  color: white;
  border-radius: 12px;
  padding: 40px 120px;
  box-shadow: 0px 0px 5px grey;
}

.buy-now-hp:hover{
  transform: scale(.98);
}

.buy-now-hp:active{
  transform: scale(.98);
}

.disclaimer{
  background: #f9f9f9;
  /* background: #fcfcfc; */
}

.folder, .folder-pages-inner, .drag-handle {
  touch-action: none;
}