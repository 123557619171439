.announcement-bar{
    background-color: black;
    /* background-color: hsl(24 9.8% 10%); */
    height: 30px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}

.space-used, .plan{
    font-family: "Geist Bold";
    color: white;
    font-size: 11px;
}

.buy{
    font-family: "Geist Bold";
    font-size: 11px;
    color: white;
    text-decoration: underline;
    cursor: pointer;

    display: flex;
    align-items: center;
}

.warning{
    font-family: "Geist Bold";
    font-size: 11px;
    color: white;
    text-align: right;;
}


