.links .add-link-btn{
    font-family: "Geist SemiBold";
    font-size: 20px;
    padding: 0px 7px;
    padding-bottom: 2px;
    height: 25px;
    display: flex;
    align-items: center;
    color: black;
    background-color: white;
    margin-right: -7px;
    border-radius: 4px;
}

.links .add-link-btn:hover {
    transform: scale(.95);
    background: #EFEFEF;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
}

.links .add-link{
    font-family: "Geist Medium";
    font-size: 12px;
    margin: 0px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.links a{padding: 4px 0px;}

.links .item:hover{background-color: #F8FAFC;}

.links .delete-link{
    display: none;
    color: #919eab;
}
.links .item:hover .delete-link{display: flex;}

.links .delete-link:hover svg{color:black}