.editor-container{
    /* width: 550px; */
    width: 100%;
    /* border-radius: 4px; */
    border: 1px solid #e4e4e7;
}

.jodit-container{
    border: 1px solid #e4e4e7;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
}

.jodit-container:not(.jodit_inline){
    border: 0px solid white;
}








.page-notes{
    /* width: 100%; */
    border: 1px solid hsl(240 5.9% 90%);
    /* border-radius: .5rem; */
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0,0,0,.05);
}

.jodit-container:not(.jodit_inline){
    background: rgba(255,255,255,.98);
}

.jodit-container:not(.jodit_inline) .jodit-workplace{
    background: rgba(255,255,255,0);
}

.jodit-container .jodit-workplace .jodit-wysiwyg{
    background: rgba(255,255,255,0);
}

.jodit-container, .jodit-container:not(.jodit_inline){
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 0px solid white;
}

.jodit-container:not(.jodit_inline) .jodit-editor__resize svg{
    width: 15px;
    height: 15px;
}

.editor-container{
    flex-direction: column;
    width: 100%;
    z-index: 4;
    
    /* position: fixed;
    left: 230px;
    top: 40px;
    box-shadow: 0px 2px 5px #ccc; */
}

@media(max-width: 768px){

    /* .jodit-container{height: 600px !important} */

    .editor-container{
    transform: translate(0px, 0px) !important;
    position: relative;
    left: 0px;
    top: -35px;
    max-width: 100%;
    margin-bottom: 40px;
    }

    .editor-handle svg{
        opacity: 0;
    }
}

.handle{
    display: flex;
    align-items: center;
    justify-content: center;

    /* border-top: 1px solid #dadada;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada; */
    /* background-color: #eee; */

    /* border-top-left-radius: 2px;
    border-top-right-radius: 2px; */
    cursor: grab;
    padding: 6px;
}

.editor-handle{
    position: absolute;
    width: calc(100% - 40px);
    top: -30px;
    right: 40px;
    border-top-left-radius: 6px;
    background: #eee;
    border: 1px solid #ccc;
    border-bottom: 0px solid #ccc;
    border-right: 0px solid #ccc;

    display: none;
}

.close-editor{
    position: absolute;
    top: -30px;
    height: 30px;
    right: 0px;
    cursor: pointer;
    width: 40px;

    padding-top: 6px;
    border-top-right-radius: 6px;
    background: #eee;
    border: 1px solid #ccc;
    border-bottom: 0px solid #ccc;

    display: none;
}

@media(min-width: 769px){   

    .editor-handle:hover{
        height: 34px;
        top: -34px;
        border-top-right-radius: 6px;
    }

    .editor-handle:active{
        height: 32px;
        top: -32px;
        border-top-right-radius: 6px;
    }
}

.close-editor:hover{
    transform: scale(1.05);
}

.close-editor svg{
    color: #666;
}

.jodit-wysiwyg{
    text-align: left;
}

.handle svg{color: #666;}
.jodit-editor__resize svg{ fill: #666 !important;}
.handle:active{ cursor: grabbing; }

.jodit-status-bar__item-right .jodit-status-bar-link{
    display: none;
}

.jodit-status-bar_resize-handle_true {
    padding-right: 35px;
}

.jodit-add-new-line{
    display: none;
}

/* .jodit-editor__resize{
    display: none;
} */

.jodit-wrapper{
    /* width: 500px;
    height: 100%;
    padding: 40px 20px; */
    border: 1px solid #dadada;
    background: black;
}

.jodit-toolbar__box{
    background: #f9f9f9 !important;
}

.jodit-toolbar-button svg {
    fill: black;
    stroke: black;
  }

  .jodit-container:not(.jodit_inline) .jodit-wysiwyg{
    padding: 8px 16px;
  }


/* Heading styles */
h1 {
    font-family: "Geist Black";
    font-size: 2.25em;
    letter-spacing: -.025em;
    margin: .25em 0px;
    padding: 0;
}

h2 {
    font-family: "Geist Black";
    font-size: 1.85em;
    letter-spacing: -.025em;
    margin: .25em 0px;
    padding: 0;
}

h3 {
    font-size: 1.5em;
    margin: .25em 0px;
    padding: 0;
}

h4 {
    font-size: 1.2em;
    margin: .25em 0px;
    padding: 0;
}

h5{
    margin: .25em 0px;
}

ol li {
    line-height: 30px;
}

p, li{
    /* font-family: Helvetica,sans-serif,Arial; */
    font-family: 'Geist Regular';
    font-size: 15px;
    letter-spacing: -.025em;
    margin: 0px;
}

.note-save-btn.color{
    background: #2563EB;
    color: white;}

.jodit_toolbar_button_customButton svg {
    display: none;
  }
  
  .jodit_toolbar_button_customButton {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-square-arrow-out-up-right"><path d="M21 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6"/><path d="m21 3-9 9"/><path d="M15 3h6v6"/></svg>');
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
  }

/* jodit.css */
.jodit-wysiwyg hr {
    border: 0; /* Remove the default border */
    height: 1px; /* Set the height */
    background-color: #e4e4ed; /* Change to your desired color */
  }
  

/* The scrollbar itself */
.jodit-container .jodit-workplace .jodit-wysiwyg::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
    height: 8px;
  }
  
  /* The part of the scrollbar that you drag */
  .jodit-container .jodit-workplace .jodit-wysiwyg::-webkit-scrollbar-thumb {
    background-color: hsl(240 4.8% 95.9%); /* Set the color of the scrollbar thumb */
    border-radius: 15px; /* Optional: make the scrollbar thumb rounded */
  }
  
  /* The track (progress bar) of the scrollbar */
  .jodit-container .jodit-workplace .jodit-wysiwyg::-webkit-scrollbar-track {
    background: transparent; /* Set the color of the track */
  }

  .editor-wrapper {
    display: flex;
    justify-content: center; /* Center horizontally */

    max-width: 900px;
    width: 500px;
    min-width: 500px;

    position: absolute;
    top: -1px;
    right: -15px;
    z-index: 3;
}

.editor-new-window{
    position: fixed;
    top: 0;
    right: 0;
    max-width: 900px;
    z-index: 10;
    min-width: 500px;
}

.editor-new-window .card{
    border-radius: 0px;
}

.editor-wrapper .max-w-100{
    max-width: 900px;
}

.editor-container {
    max-width: 100%; /* Responsive max-width */
    width: 100%; /* Sets an initial width for the editor */
}

.editor-resize{
    color: rgba(0,0,0,.9);
    position: absolute;
    left: 5px;
    top: calc(50vh - 121px);
    display: flex;
    align-items: center;
    background: #e4e4e7;
    border-radius: 2px;
    padding: 3px 0px;
    z-index: 2;
    cursor: ew-resize;
    z-index: 12;
}

.editor-resize-two{
    position: absolute;
    height: 100%;
    top: 0;
    width: 599px;
    left: -475px;
    opacity: .4;
    /* background: grey; */
    z-index: 11;
}

.editor-wrapper .card{
    border-radius: 0px;
}

.expand-editor{
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    padding: 3px;

    border: 1px solid white;
    border-radius: 4px;
    color: rgba(0, 0, 0, .7);
}

.expand-editor:hover{
    background-color: #F8FAFC;
    border: 1px solid #eee;
}

.jodit .jodit-wysiwyg {
    resize: none; /* Disable the default resize on the right */
}

.jodit .jodit-resizer, .jodit-editor__resize {
    left: 0; /* Position the resize handler to the left */
    right: unset; /* Remove the right positioning */
    transform: translateX(-100%); /* Adjust to align correctly on the left */
}

/* Ensures long words or URLs wrap to the next line */
.jodit-wysiwyg {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

/* Targets specifically links and long text inside the editor */
.jodit-wysiwyg a,
.jodit-wysiwyg p,
.jodit-wysiwyg div {
    word-break: break-word;
}