.search h3{
    margin: 0px;
    margin-right:10px;
}

.search input{
    width: calc(100% - 81px);
    padding: 8px 10px;
    border: 1px solid #e4e4e7;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
}

.search button{
    font-family: "Geist Medium";
    background-color: black;
    color: white;
    border-radius: 4px;
    padding: 8px 16px;
}

.search .active{
    text-decoration: underline;
}

.search button:hover{transform: scale(.98)}
.search button:active{transform: scale(.95)}

.add-a-video, .search-google, .search-youtube, .search-chatgpt{
    cursor: pointer;
}

.add-a-video:hover, .search-google:hover, .search-youtube:hover, .search-chatgpt:hover{
    text-decoration: underline;
    color: #1E90FF;
}

.search-only{
    width: 506px;
    padding: 0px;
    border: 0px solid white;
    box-shadow: none;
    margin-right: 20px;
    margin-bottom: 5px;
}


.add-a-video-card .search-options{
    margin-right: 85px;
}