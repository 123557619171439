.links{
    max-height: calc(100vh - 225px);
    overflow-y: scroll;
}

.links button{
    font-family: "Geist SemiBold";
    font-size: 20px;
    padding: 0px 7px;
    padding-bottom: 2px;
    height: 25px;
    display: flex;
    align-items: center;
    color: black;
    background-color: #F8FAFC;
    margin-right: -7px;
    border-radius: 4px;
}

.links button{
    font-size: 12px;
}

.links button:hover{
    transform: scale(.95);
    background: #EFEFEF;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
}
.links button:active{transform: scale(.90)}

.links .item button{display: none;}
.links .item:hover button{display: flex;}

.links h3{margin: 0px;}

.links .item{
    font-family: 'Geist Regular';
    font-size: 13px;
    line-height: 1rem;

    padding: 0px 10px;
    /* border-bottom: 1px solid #e4e4e7; */

    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
    /* height: 26px; */
    padding-left: 0px;
}

.links a{color: black; text-decoration: none;}
.links a:hover{text-decoration: underline;}

.links .item img{width:16px; height: 16px;}