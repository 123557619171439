/* General Styles */
.timer-container {
    background: black;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
    text-align: center;
    padding: 10px;
    width: auto;

    position: fixed;
    right: 20px;
    top: 85px;
    z-index: 11;
    width: 225px;

    background: linear-gradient(90deg, black, rgba(0,0,0,.9));
    /* overflow-y: scroll; */
    /* max-height: 80vh; */
  }

  .timer-header{
    margin-bottom: 5px;
    margin-top: -10px;
    border-bottom: 1px solid #666;
    width: calc(100% + 20px);
    margin-left: -10px;
    padding: 0px 10px;
    background: rgba(255, 255, 255, .1);
    padding-top: 5px;
    padding-bottom: 3px;
  }

  .timer-handle{
    color: rgba(255,255,255,.7);
    cursor: grab;
    margin: auto;
    /* margin-top: -5px; */
    /* width: calc(100% - 40px); */
  }

  .timer-handle:active{
    cursor: grabbing;
  }

  @media(max-width: 768px) and (min-width:501px){
    .mobile-checked.timer-container {
      top: 101px;
      right: 0px;
    }
  }

  @media(max-width: 500px){
    .mobile-checked.timer-container {
      top:115px;
      right: 0px;
    }
  }

  .logged-in-at{
    /* position: absolute;
    top: 20px; */
    font-size: 11px;
    font-family: 'Geist Medium';
    color: hsl(240 5.9% 90%);
    text-align: left;
  }

  .timer-tabs{
    display: flex;
    font-family: 'Geist Black';
    font-size: 12px;
    color: white;
    margin-top: 10px;
    justify-content: space-evenly;
    margin-bottom: 10px;
    column-gap: 5px;
    cursor: pointer;
    border-bottom: 1px solid #666;
    padding-bottom: 8px;
  }

  .pomodoro-tab, .stopwatch-tab{
    padding: 4px;
    border-radius: 4px;
  }

  .tab-active{
    background: #D881F8;
  }

  .timer-btn-active{
    border: 1px solid #D881F8 !important; 
  }

  .pomodoro-hide{
    color: white;
    font-size: 10px;
    font-family: 'Geist Medium';
    /* position: absolute; */
    /* top: 3px; */
    /* right: 3px; */

    color: rgba(255,255,255,.7);
    cursor: pointer;
    z-index: 2;
    padding: 1px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
  }

  .pomodoro-hide:hover{
    color: white;
    background-color: #D881F8;
  }
  
  .timer-display {
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 100%;
  }
  
  .button-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 10px;
  }
  
  .timer-container button {
    display: flex;
    align-items: center;
    font-family: 'Geist Bold';
    font-size: 13px;
    padding: 2px 6px;
    background-color: rgba(0, 0, 0, 0);
    text-wrap: nowrap;
    color: #fff;
    border: 0px solid black;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .timer-container button:active{
    transform: scale(.98);
  }
  
  .timer-container button:disabled {
    cursor: not-allowed;
  }
  
  .timer-container button:hover:not(:disabled) {
    background-color: #D881F8;
  }
  
  .timer-controls button {
    background-color: black;
    border: 1px solid rgba(0, 0, 0, 0);
  }
  
  .timer-controls button:hover:not(:disabled) {
    background-color: black;
    border: 1px solid #666;
  }
  
  .start-container{
    width: fit-content;
    color: white;
    margin: auto;
    height: fit-content;
    z-index: 2;
    margin-top: -42px;
}


  .reset-container{
    position: absolute;
    right: 5px;
    bottom: -8px;
    width: fit-content;
    color: white;
    margin: auto;
    height: fit-content;
    z-index: 2;
    margin-top: -42px;
  }

  .reset-container button{
    font-size: 11px;
    padding: 4px 4px;
  }

  .run-timer{
    position: relative;
    width: 140px;
    height: 140px;
}

.CircularProgressbar:hover{
    transform: scale(.98);
  }

  .CircularProgressbar:active{
    transform: scale(.96);
  }

  .CircularProgressbar{
    position: relative;
    cursor: pointer;
    border-radius: 115px;
    box-shadow: 0px 0px 15px rgba(255,255,255, .5);
  }

  .CircularProgressbar-text{
    font-family: 'Geist Black';
    font-size: 24px !important;
    font-weight: bold;
    fill: black;
    color: black;
  }

  .CircularProgressbar .CircularProgressbar-trail{
    stroke-width: 4px;
    border-radius: 100%;
  }

  .CircularProgressbar .CircularProgressbar-path{
    stroke-width: 5px;
    border-radius: 100%;
  }













  /* STOPWATCH */
  .stopwatch{
    position: relative;
    /* width: 125px; */
  }
  
  .stopwatch .time{
    display: flex; 
    align-items: center;
    justify-content: center;

    font-family: 'Geist Black';
    font-size: 34px !important;
    font-weight: bold;
    color: white;
    margin-bottom: 17px;

    border: 7px solid #D881F8;
    border-radius: 100%;
    width: 136px;
    height: 136px;

    margin-top: 2px;
    cursor: pointer;

    box-shadow: 0px 0px 15px rgba(255,255,255, .5);
  }

  .stopwatch .time:hover{
    transform: scale(.98);
  }

  .stopwatch .time:active{
    transform: scale(.96);
  }
  
  .stopwatch button{
    display: flex;
    align-items: center;

    font-family: 'Geist Bold';
    font-size: 11px;
    padding: 2px 6px;
    background-color: rgba(0, 0, 0);
    text-wrap: nowrap;
    color: #fff;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .stopwatch .start-container{
    margin-top: -57px;
  }

  .stopwatch-clear{
    position: absolute;
    right: -31px;
    bottom: -26px;
  }

  .task-text {
    display: inline-block;
    /* margin-left: 10px; */
    width: 100%;

    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}