.youtube{
    position: relative;
    z-index: 2;
}
.youtube-inner{
    min-height: 600px;
    overflow-y: scroll;
    height: 100vh;
    border: 1px solid #e4e4e7;
    margin-left: -1px;
    padding: 25px 10px 10px 15px;
    width: 100%;
    padding-bottom: 300px;
    position: relative;

    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    background-color: white;
    z-index: 2;
}

.youtube-open-window{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 55%;
    height: 100vh;
}

.expand-window{
    position: absolute;
    top: 1px;
    left: 0px;
    cursor: pointer;
    padding: 3px;

    background: rgba(255,255,255, .9);
    border: 1px solid white;
    border-radius: 4px;
    color: rgba(0, 0, 0, .7);
    z-index: 3;
}

.expand-window:hover{
    background-color: #F8FAFC;
    border: 1px solid #eee;

}

.youtube .card{text-align: right !important;padding-top:7px;padding-bottom:11px;}

.youtube .delete-button{opacity: 1 !important; margin-top: -20px;}

.youtube-link{
    font-size: 12px;
    font-family: 'Geist Regular';
    display: flex;
    align-items: center;

    color: #919eab;
    text-decoration: none;
}

.youtube .card .video-link{
    font-size: 12px;
    color: #919eab;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: end;
    column-gap: 5px;
    margin-top: 5px;
}

.youtube .card .video-link:hover, .youtube-link:hover{
    text-decoration: underline;
    color:#1E90FF;
}

.youtube .delete-button:hover{
    color: black;
}



/* 
        o8o   .o88o.                                                       
   .dP  `"'   888 `"                                                Yb     
 .dP   oooo  o888oo  oooo d8b  .oooo.   ooo. .oo.  .oo.    .ooooo.   `Yb   
dP     `888   888    `888""8P `P  )88b  `888P"Y88bP"Y88b  d88' `88b    `Yb 
Yb      888   888     888      .oP"888   888   888   888  888ooo888    .dP 
 `Yb    888   888     888     d8(  888   888   888   888  888    .o  .dP   
   `Yb o888o o888o   d888b    `Y888""8o o888o o888o o888o `Y8bod8P' dP                                                                   
*/






/* IFRAME */

.auto-resizable-iframe {
    margin: 0px auto;
    background: white;
    position: relative;

    background: #eee;
    /* border: 2px solid black;
    box-shadow: 0px 0px 5px black; */
    border: 1px solid hsl(240 5.9% 90%);
}

.auto-resizable-iframe>div {
    position: relative;
    height: 0px;
}

.auto-resizable-iframe iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.iframe-div {
    padding-bottom: 57% !important;
    z-index: 2;
}

.react-resizable-handle-se{
    border: 5px solid red;
}