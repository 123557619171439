.bookmarks{
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Align items properly */

    padding: 0px 20px;
    column-gap: 10px;
    row-gap: 10px;
}

.bookmarks button{
    font-family: "Geist SemiBold";
    font-size: 20px;
    padding: 5px 10px;
    padding-bottom: 2px;
    height: 25px;
    display: flex;
    align-items: center;
    color: black;
    background-color: #f4f4f5;
    border: 1px solid #f4f4f5;
    /* margin-right: -7px; */
    border-radius: 4px;
}

.bookmarks button:hover{
    transform: scale(.95);
    background: #EFEFEF;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
    border: 1px solid #ccc;
}
.bookmarks button:active{transform: scale(.90)}

.bookmarks h3{
    margin: 0px;
}

.bookmarks button span{
    font-family: "Geist SemiBold";
    font-size: 20px;
}

.bookmarks button{
    padding: 5px 10px;
    padding-bottom: 6px;
    height: 34px;
    display: flex;
    align-items: center;
    column-gap: 5px;

    color: black;
    background-color: white;

    border: 1px solid #e4e4e7;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
}

.bookmarks button:hover{
    transform: scale(.95);
    background: #EFEFEF;
}
.bookmarks button:active{transform: scale(.90)}

.bookmarks a{
    text-decoration: none;
    color: black;
    width: 140px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link-btn{
    font-family: "Geist SemiBold";
    font-size: 14px;

    background-color: white;
    border: 1px solid #e4e4e7;
    border-radius: 6px;
    padding: 8px 8px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}

.link-btn-inner{
    font-family: "Geist SemiBold";
    font-size: 12px;
    color: black;
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    line-height: 1;
}

.link-btn:hover{
    /* transform: scale(.95);
    background-color:#f4f4f5; */
    cursor: pointer;
}

.bookmarks-btn:active{transform: scale(.90)}










/* .bookmarks-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 10px;
} */

.bookmarks .sortable-item {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0px 0px;

    /* padding: 5px 5px; */
    /* border: 1px solid #ccc; */

    border-radius: 4px;
    background-color: white;
    transition: all 0.2s ease;

    /* width: 140px;
    height: 35px; */
    transition: transform 150ms ease; /* Transition ensures smooth drag and drop */
    will-change: transform; /* Optimization for better rendering */
}

.bookmarks .sortable-item:hover {
    background: #F8FAFC;
}

.dragging {
    opacity: 0.9;
    z-index: 999;
    transition: none; /* Prevent extra transition during drag */
}

/* .sortable-item.is-dragging {
    opacity: 0.5;
    z-index: 999;
} */

.bookmarks .remove-btn {
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.bookmarks svg{
    display: flex;
    align-items: center;
    color: #919eab;
}